<template>
	<div>
		<v-row class="pa-0">
			<v-col cols="12" md="3" lg="3" class style="padding-top:1px!important;">
				<v-card
					class="mx-auto"
					dark
					style="background-image: linear-gradient(-40deg, #23a450 0%, #50c771 100%)"
				>
					<v-list-item>
						<v-list-item-content>
							<v-list-item-title class="body-1" style="font-weight:500">{{currentBalance || 0}} UND</v-list-item-title>
							<v-list-item-subtitle>
								<small>BONO:</small>
								{{currentBono}} UND
							</v-list-item-subtitle>
						</v-list-item-content>

						<v-list-item-avatar color="white">
							<v-icon color="#32325d" size="15">UND</v-icon>
						</v-list-item-avatar>
					</v-list-item>

					<v-card-actions class="pt-0">
						<v-btn text small block @click="recargar()">
							<small class>Recargar saldo</small>
							<v-icon class="ml-auto" dark>mdi-menu-right</v-icon>
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>

			<v-col cols="12" md="3" lg="3" class style="padding-top:1px!important;">
				<v-card
					class="mx-auto"
					dark
					style="background-image: linear-gradient(-40deg, #22344f 0%, #1e2a3c 100%)"
				>
					<v-list-item>
						<v-list-item-content>
							<v-list-item-title
								class="body-1"
								style="font-weight:500"
							>{{currentSimbolo}} {{currentBalance * currentCambio || 0}} ({{currentMoneda}})</v-list-item-title>
							<v-list-item-subtitle>CAMBIO</v-list-item-subtitle>
						</v-list-item-content>

						<v-list-item-avatar color="white">
							<v-icon color="#32325d">{{currentSimbolo}}</v-icon>
						</v-list-item-avatar>
					</v-list-item>

					<v-card-actions class="pt-0">
						<v-btn text small block @click="recargar()">
							<small class>Recargar saldo</small>
							<v-icon class="ml-auto" dark>mdi-menu-right</v-icon>
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>

			<v-col cols="12" md="3" lg="3" class style="padding-top:1px!important;">
				<v-card
					class="mx-auto"
					dark
					style="background-image: linear-gradient(140deg, #ffb900 0%, #ffde85 100%)"
				>
					<v-list-item>
						<v-list-item-content>
							<v-list-item-title class="body-1" style="font-weight:500">{{premios.length || 0}}</v-list-item-title>
							<v-list-item-subtitle>TICKETS PREMIADOS</v-list-item-subtitle>
						</v-list-item-content>

						<v-list-item-avatar color="#fff">
							<v-icon color="#32325d">mdi-ticket</v-icon>
						</v-list-item-avatar>
					</v-list-item>

					<v-card-actions class="pt-0">
						<v-btn text small block to="/rewards">
							<small class>Hace una semana</small>
							<v-icon class="ml-auto" size="20" dark>mdi-calendar</v-icon>
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>

			<v-col cols="12" md="3" lg="3" class style="padding-top:1px!important;">
				<v-card
					class="mx-auto"
					dark
					style="background-image: linear-gradient(-40deg, #307ee0, #01aeef)"
				>
					<v-list-item>
						<v-list-item-content>
							<v-list-item-title class="body-1" style="font-weight:500">{{jugadas.length || 0}}</v-list-item-title>
							<v-list-item-subtitle>JUGADAS</v-list-item-subtitle>
						</v-list-item-content>

						<v-list-item-avatar color="#fff">
							<v-icon color="#32325d">mdi-horseshoe</v-icon>
						</v-list-item-avatar>
					</v-list-item>

					<v-card-actions class="pt-0">
						<v-btn text small block to="/plays">
							<small class>Hace una semana</small>
							<v-icon class="ml-auto" size="20" dark>mdi-calendar</v-icon>
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-card :color="colors.sidebar">
					<v-card-text class="pa-0">
						<v-card-title class="py-0 pt-2">
							<div class="d-flex align-center" style="width:100%">
								<b style="font-weight:500;font-size:14px" class="white--text">OPERACIONES</b>
								<div class="ml-auto">
									<v-radio-group hide-details class="ml-3" dense dark v-model="bono" row>
										<v-radio label="Saldo" :value="0"></v-radio>
										<v-radio label="Bono" :value="1"></v-radio>
									</v-radio-group>
								</div>
							</div>
						</v-card-title>
						<div class="caption mb-2 ml-4 white--text">Hace una semana</div>
						<v-divider></v-divider>
						<v-data-table
							class="horse"
							:loading="loading"
							:height="$store.state.windowSize.x < 940?'calc(100vh - 260px)':'calc(100vh - 348px)'"
							fixed-header
							:headers="headers"
							:items="operations"
							:items-per-page="10"
						>
							<template v-slot:no-data>
								<h1 class="mt-3 font-weight-regular">Selecione su cartera</h1>
							</template>
							<template v-slot:item.tipo="{ item }">
								<span class="text-capitalize">{{item.tipo}}</span>
							</template>

							<template v-slot:item.monto="{ item }">
								<v-chip small :color="statusColor(item.tipo)" dark>
									<div v-show="item.tipo == 'apuesta'">-</div>
									{{ item.monto }} UND
								</v-chip>
							</template>
							<template v-slot:item.balance="{ item }">
								<b style="font-weight:500">{{ item.balance }} UND</b>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<br />
		<br />
	</div>
</template>

<script>
import axios from "axios";
import date from "../../date.js";
import statusColor from "../../statusColor.js";
import parseDate from "../../parseDate.js";

export default {
	data() {
		return {
			loading: false,
			date: date.today(),
			premios: this.$store.state.premios,
			jugadas: 0,
			operations: [],
			headers: [
				{ text: "FECHA", value: "fecha" },
				{ text: "HORA", value: "hora" },
				{ text: "REFERENCIA", value: "ref" },
				{ text: "TIPO", value: "tipo" },
				{ text: "MONTO", value: "monto", align: "center" },
				{ text: "BALANCE", value: "balance" },
			],
			currentBalance: 0,
			currentBono: 0,
			currentCambio: 0,
			currentMoneda: "",
			currentSimbolo: "",
			bono: 0,
		};
	},
	watch: {
		bono() {
			this.getOperations(this.idmoneda);
		},
		idmoneda(e) {
			this.getJugadas(e);
			this.getOperations(e);
			this.getPremios(e);
			this.currentSaldo(e);
		},
	},
	mounted() {
		this.parseDate();
		if (this.premios >= 0) {
			this.getPremios(this.idmoneda);
		}
		this.getJugadas(this.idmoneda);
		this.getOperations(this.idmoneda);
		this.currentSaldo(this.idmoneda);
	},
	computed: {
		idmoneda() {
			return this.$store.state.idmoneda;
		},
		saldos() {
			return this.$store.state.saldos;
		},
		colors() {
			return this.$store.state.colors;
		},
	},
	methods: {
		currentSaldo(e) {
			let index = this.saldos.findIndex((x) => x.idmoneda === e);
			this.currentBalance = this.saldos[index].balance;
			this.currentBono = this.saldos[index].bono;
			this.currentCambio = this.saldos[index].cambio;
			this.currentSimbolo = this.saldos[index].simbolo;
			this.currentMoneda = this.saldos[index].moneda;
		},
		recargar() {
			this.$store.state.dialogRe = true;
		},
		statusColor,
		parseDate,
		getPremios(id) {
			axios({
				method: "get",
				url: `new_premios`,
				params: {
					idmoneda: id,
				},
			}).then((response) => {
				this.$store.state.premios = response.data.premios[0];
			});
		},
		getJugadas(id) {
			axios({
				method: "get",
				url: `new_jugadas/${this.date}/${date.today()}`,
				params: {
					idmoneda: id,
				},
			}).then((response) => {
				this.jugadas = response.data.jugadas;
			});
		},
		getOperations(id) {
			this.loading = true;
			axios({
				method: "get",
				url: `new_transacciones/${this.date}/${date.today()}`,
				params: {
					idmoneda: id,
					bono: this.bono,
				},
			}).then((response) => {
				this.operations = response.data.transacciones;
				this.loading = false;
			});
		},
	},
};
</script>

<style>
</style>